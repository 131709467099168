import React, { useEffect, useState } from "react";
import { Navbar } from "../components/navbar/Navbar";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";

const DeleteAccount = () => {
  const [step, setStep] = useState("initial");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [deleteCode, setDeleteCode] = useState("");
  const [errorStat, setErrorStat] = useState("");
  const [errorStat2, setErrorStat2] = useState("");
  const [width, setWidth] = useState("70%");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1024) {
        setWidth("30%");
      } else {
        setWidth("70%");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDelete = async () => {
    setLoading(true);
    try {
      const res = await axios.post(
        "https://scavenge.michofat.com/scavenge/v1/getdeletecode",
        { email }
      );
      if (res) {
        setStep("verification");
        setErrorStat("");
      }
    } catch (error) {
      setErrorStat(
        error?.response?.data?.message || error?.response?.data?.error
      );
    } finally {
      setLoading(false);
    }
  };

  const handleFinalDelete = async () => {
    setLoading(true);
    try {
      const res = await axios.post(
        "https://scavenge.michofat.com/scavenge/v1/deleteaccount",
        { email, deletecode: Number(deleteCode) }
      );
      if (res) {
        setStep("final");
        setEmail("");
        setDeleteCode("");
        setErrorStat2("");
      }
    } catch (error) {
      setErrorStat2(error?.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setStep("initial");
  };

  return (
    <>
      <Navbar />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            borderRadius: 8,
            padding: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            boxShadow:
              "0 15px 30px -5px rgba(0, 0, 0, 0.2), 0 10px 15px -3px rgba(0, 0, 0, 0.1)",
            width,
          }}
        >
          {step === "initial" && (
            <>
              <h3 style={{ color: "black", fontWeight: "bolder" }}>
                Delete your account
              </h3>
              <h3
                style={{
                  color: "gray",
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "14px",
                }}
              >
                Enter your email address to receive verification code
              </h3>
              <input
                type="text"
                value={email}
                style={{
                  borderWidth: 1,
                  borderColor: "gray",
                  marginTop: 10,
                  padding: 14,
                  borderRadius: 8,
                  width: "80%",
                }}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email address"
              />
              <button
                onClick={handleDelete}
                style={{
                  backgroundColor: "green",
                  border: 0,
                  color: "white",
                  padding: 10,
                  borderRadius: 8,
                  width: "70%",
                  marginTop: 6,
                  fontWeight: "bolder",
                }}
              >
                {loading ? (
                  <ClipLoader color={"white"} loading={loading} size={24} />
                ) : (
                  "Submit"
                )}
              </button>
              {errorStat && <p style={{ color: "red" }}>{errorStat}</p>}
            </>
          )}

          {step === "verification" && (
            <>
              <h3 style={{ color: "black", fontWeight: "bolder" }}>
                Verify your account
              </h3>
              <h3
                style={{
                  color: "gray",
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "14px",
                }}
              >
                Enter the verification code sent to {email}
              </h3>
              <input
                type="number"
                style={{
                  borderWidth: 1,
                  borderColor: "gray",
                  marginTop: 20,
                  padding: 10,
                  borderRadius: 8,
                  width: "80%",
                }}
                value={deleteCode}
                onChange={(e) => setDeleteCode(e.target.value)}
                placeholder="Enter code"
              />
              <button
                onClick={handleFinalDelete}
                style={{
                  backgroundColor: "green",
                  color: "white",
                  padding: "10px",
                  border: "none",
                  borderRadius: "8px",
                  width: "70%",
                  marginTop: 6,
                  fontWeight: "bolder",
                }}
              >
                {loading ? (
                  <ClipLoader color={"white"} loading={loading} size={24} />
                ) : (
                  "Verify Code"
                )}
              </button>
              {errorStat2 && <p style={{ color: "red" }}>{errorStat2}</p>}
            </>
          )}

          {step === "final" && (
            <>
              <h3>ACCOUNT DELETED SUCCESSFULLY</h3>
              <button
                onClick={handleClose}
                style={{
                  backgroundColor: "gray",
                  color: "white",
                  padding: "10px 20px",
                  border: "none",
                  borderRadius: "8px",
                  marginTop: 20,
                  width: "70%",
                }}
              >
                Close
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default DeleteAccount;
